import React, {useEffect, useState} from 'react'
import { Helmet } from "react-helmet"
import { Link } from 'react-router-dom'
import { Facebook } from 'react-content-loader'
import './components/css/Home.css'

const Home = () => {
    const [home, setHome] = useState([])
    const [injuries, setinjuries] = useState([])
    const [isLoader, setLoader] = useState(true)

    useEffect(() => {
        const REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL
        const REACT_APP_WORDPRESS_API_URL  = process.env.REACT_APP_WORDPRESS_API_URL
        const fetchInjuriesData = () => {
            fetch(REACT_APP_WORDPRESS_API_URL+"/practice_areas").then((res) => res.json()).then((response) => {
                setinjuries(response)
                setLoader(false)
            })
        }
    
        const fetchHomeData = () => {
            fetch(REACT_APP_LIVE_BACKEND_BASE_URL+"api/home-page-discription").then((res) => res.json()).then((response) => {
                setHome(response.data)
            })
        }
        
        fetchInjuriesData()
        fetchHomeData()
    }, [])
    
    return (
        <>
            <Helmet>
                <title>{home.title}</title>
                <meta name="description" content={home.meta_description} />
            </Helmet>
            
            <section className="btf-sec injury-lawyer-btf-sec">
                <div className="container">
                    <div className="title injury-lawyer-btf-sec-title-section" loading="lazy">
                        <h1>Streamline Your Search for an Injury Lawyer</h1>
                        <h2>Easily Locate a Personal Injury Lawyer Near You</h2>
                    </div>
                    <ul className='ms-2 my-3' loading="lazy">
                        <li>Begin by clicking on the injury type icon below.</li> 
                        <li>Then, you’ll be prompted to select your state on the next screen.</li> 
                        <li>After choosing your state, you’ll be taken to a page with a selection of cities in that state.</li> 
                        <li>Choose the city that is closest to your home town, and you’ll find the list of personal injury lawyer profiles.</li>
                    </ul>

                    <div className="lawyerCardWrapper row">
                        {
                            isLoader ? <Facebook /> : (injuries.length > 0 ? (injuries.map((injury, i) => {
                                return(<div className="col-md-6 col-lg-4 col-xl-3 mb-4" key={i}>
                                    <div className="injury-lawyer-card">
                                        <Link to={`${injury.slug}`}>
                                            <div className="card-img text-center">
                                                <img src={injury.image} alt='' width='55' height='55' loading='lazy'  />
                                            </div>
                                            <div className="card-title">
                                                <p>{injury.title}</p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>)
                            })) : '')
                        }
                    </div>
                </div>
            </section>
        </>    
    )
}
export default Home;