import React from 'react';
import { Findlawyer } from '../pages/Welcome';
import Home from '../pages/Home';
import { Flawyers } from '../pages/Flawyers';
import { CCity } from '../pages/CCity';
import { PracticeAreas } from '../pages/PracticeAreas';
import { FrontPracticeAreas } from '../pages/FrontPracticeArea';
import { PracticeAreasLawyerList } from '../pages/PracticeAreasLawyerList';
import { Signin } from '../pages/Signin';
import { Signup } from '../pages/Signup';
import { LawyerStates } from '../pages/LawyerStates';
import { LawyerCities } from '../pages/LawyerCities';
import Lawyerdashboardprofile from '../lawyer_dashboard/pages/Lawyerdashboardprofile';
import Lawyerdashboard from '../lawyer_dashboard/pages/Lawyerdashboard';
import Lawyerprofilelist from '../lawyer_dashboard/pages/Lawyerprofilelist';
import Createbadge from '../lawyer_dashboard/pages/Createbadge';
import LawyerContactUs from '../lawyer_dashboard/pages/LawyerContactUs';
import LawyerChangePassword from '../lawyer_dashboard/pages/LawyerChangePassword';
import GetPublished from '../lawyer_dashboard/pages/GetPublished';
import AccountManagement from '../lawyer_dashboard/pages/Accountmanagement';
import Admindashboard from '../admin_dashboard/pages/Admindashboard';
import Addbadge from '../admin_dashboard/pages/Addbadge';
import AddPackage from '../admin_dashboard/pages/Addpackage';
import AddPracticeArea from '../admin_dashboard/pages/Addpracticearea';
import Badge from '../admin_dashboard/pages/Badge';
import UpdateBadge from '../admin_dashboard/pages/UpdateBadge';
import Lawyerlist from '../admin_dashboard/pages/Lawyerlist';
import Userlist from '../admin_dashboard/pages/Userlist';
import LawyerDashboardContent from '../admin_dashboard/pages/LawyerDashboardContent';
import EditDashboardContent from '../admin_dashboard/pages/EditDashboardContent';
import Adminlawyerprofile from '../admin_dashboard/pages/Adminlawyerprofile';
import PracticeAreaList from '../admin_dashboard/pages/Practicearealist';
import AdminSignIn from '../admin_dashboard/pages/AdminSignIn';
import AddLawyerProfile from '../admin_dashboard/pages/Addlawyerprofile';
import Adduserprofile from '../admin_dashboard/pages/Adduserprofile';
import Updateuserprofile from '../admin_dashboard/pages/Updateuserprofile';
import EditLawyerProfile from '../admin_dashboard/pages/EditLawyerProfile';
import FrontLawyerProfile from '../pages/FrontLawyerProfile';
import ClaimProfileSearch from '../pages/ClaimProfileSearch';
import ClaimProfile from '../pages/ClaimProfile';
import SingleClaimProfile from '../pages/SingleClaimProfile';
import ChooseClaimMethod from '../pages/ChooseClaimMethod';
import ForgetPassword from '../pages/ForgetPassword';
import ResetPassword from '../pages/ResetPassword';
import EditPracticeArea from '../admin_dashboard/pages/EditPracticeArea';
import LawyerProfileByPractice from '../pages/LawyerProfilesByPractice';
import EditStateCity from '../admin_dashboard/pages/EditStateCity';
import StateCityList from '../admin_dashboard/pages/StateCityList';
import CityList from '../admin_dashboard/pages/CityList';
import UpdateCity from '../admin_dashboard/pages/UpdateCity';
import PackageList from '../admin_dashboard/pages/PackageList';
import EditPackage from '../admin_dashboard/pages/EditPackage';
import LogOut from '../pages/LogOut';
import AddLogo from '../admin_dashboard/pages/AddLogo';
import Changepassword from '../admin_dashboard/pages/Changepassword';
import AddFrontSocial from '../admin_dashboard/pages/AddFrontSocial';
import FrontSocialList from '../admin_dashboard/pages/FrontSocialList';
import EditFrontSocial from '../admin_dashboard/pages/EditFrontSocial';
import AddPopularLocation from '../admin_dashboard/pages/AddPopularLocation';
import PopularLocationList from '../admin_dashboard/pages/PopularLocationList';
import EditPopularLocation from '../admin_dashboard/pages/EditPopularLocation';
import AddForTheInjured from '../admin_dashboard/pages/AddForTheInjured';
import ForTheInjuredList from '../admin_dashboard/pages/ForTheInjuredList';
import EditForTheInjured from '../admin_dashboard/pages/EditForTheInjured';
import ForAttorneyList from '../admin_dashboard/pages/ForAttorneyList';
import AddForAttorney from '../admin_dashboard/pages/AddForAttorney';
import EditForAttorney from '../admin_dashboard/pages/EditForAttorney';
import Copyright from '../admin_dashboard/pages/Copyright';
import MissingInformation from '../pages/MissingInformation';
import AddSidebarBanner from '../admin_dashboard/pages/AddSidebarBanner';
import SidebarBanner from '../admin_dashboard/pages/SidebarBanner';
import UpdateSidebarBanner from '../admin_dashboard/pages/UpdateSidebarBanner';
import HomePage from '../admin_dashboard/pages/HomePage';
import Cookies from 'universal-cookie';
import Header from '../components/Header'
import Footer from '../components/Footer'
import LawyerHeader from '../components/LawyerHeader'
import LawyerFooter from '../components/LawyerFooter'
import AdminLayout from '../Layout/AdminLayout'
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"


function Routes() {

  const cookies = new Cookies();
  if (typeof cookies.get('LAWYER') !== "undefined") {
    return (
      <Router basename='/'>
        <LawyerHeader />
        <Switch>
          <Route path="/:url*" exact strict render={({ location }) => {
            if (location.pathname.substr(-1) !== '/') {
              return <Redirect to={`${location.pathname}/`} />;
            }
            return null
          }}/>
          <Route exact path="/edit-profile/" component={Lawyerdashboardprofile} />
          <Route exact path="/edit-profile/:id" component={Lawyerdashboardprofile} />
          <Route exact path="/view-profile/" component={Lawyerprofilelist} />
          <Route exact path="/get-your-badge/" component={Createbadge} />
          <Route exact path="/account-management/" component={AccountManagement} />
          <Route exact path="/account-management/:thanks/" component={AccountManagement} />
          <Route exact path="/dashboard/" component={Lawyerdashboard} />
          <Route exact path="/dashboard/:thanks/" component={Lawyerdashboard} />
          <Route exact path="/contact-us/" component={LawyerContactUs} />
          <Route exact path="/change-password/" component={LawyerChangePassword} />
          <Route exact path="/get-published/" component={GetPublished} />
          <Route exact path="/logout/" component={LogOut} />
          <Route exact path="/" component={Home} />
          <Route exact path="/findlawyer/" component={Findlawyer} />
          <Route exact path="/find-a-lawyer/" component={Flawyers} />
          <Route exact path={["/practice-areas/:id/"]} component={CCity} />
          <Route exact path="/practice-area/" component={FrontPracticeAreas} />
          <Route exact path={["/practice-areas/", "/practice-areas/:state/", "/practice-areas/:state/:city/"]} component={PracticeAreas} />
          <Route exact path="/practice-areas-lawyer-list/:stateid/:id/:practice/" component={PracticeAreasLawyerList} />
          <Route exact path="/claim-profile-search/" component={ClaimProfileSearch} />
          <Route exact path="/claim-profile/:name/:lawyerstate/:lawyercity/" component={ClaimProfile} />
          <Route exact path="/single-claim-profile/:id/" component={SingleClaimProfile} />
          <Route exact path="/choose-method/:id/" component={ChooseClaimMethod} />
          <Route exact path="/lawyer/:id/" component={FrontLawyerProfile} />
          <Route exact path="/:injury/" component={LawyerStates} />
          <Route exact path="/:injury/:state/" component={LawyerCities} />
          <Route exact path="/:practice/:lawyerstate/:lawyercity/" component={LawyerProfileByPractice} />
          <Redirect to="/" />
        </Switch>
        <LawyerFooter />
      </Router>
    )
  } else if (typeof cookies.get('ADMIN') !== "undefined") {
    return (
      <Router basename='/'>
        <AdminLayout>
        <Switch>
          <Route path="/:url*" exact strict render={({ location }) => {
            if (location.pathname.substr(-1) !== '/') {
              return <Redirect to={`${location.pathname}/`} />;
            }
            return null
          }}/>
          <Route exact path="/admin-dashboard/" component={Admindashboard} />
          <Route exact path="/add-badge/" component={Addbadge} />
          <Route exact path="/add-package/" component={AddPackage} />
          <Route exact path="/add-practice-area/" component={AddPracticeArea} />
          <Route exact path="/badge/" component={Badge} />
          <Route exact path="/badge/:id/" component={UpdateBadge} />
          <Route exact path="/lawyer-list/" component={Lawyerlist} />
          <Route exact path="/user-list/" component={Userlist} />
          <Route exact path="/lawyer-dashboard-content/" component={LawyerDashboardContent} />
          <Route exact path="/admin-lawyer-profile/:id/" component={Adminlawyerprofile} />
          <Route exact path="/practice-area-list/" component={PracticeAreaList} />
          <Route exact path="/add-lawyer/" component={AddLawyerProfile} />
          <Route exact path="/add-user/" component={Adduserprofile} />
          <Route exact path="/edit-user-profile/:id/" component={Updateuserprofile} />
          <Route exact path="/edit-lawyer-profile/:id/" component={EditLawyerProfile} />
          <Route exact path="/edit-dashboard-content/:id/" component={EditDashboardContent} />
          <Route exact path="/package-list/" component={PackageList} />
          <Route exact path="/edit-practice-area/:id/" component={EditPracticeArea} />
          <Route exact path="/edit-state-city/:id/" component={EditStateCity} />
          <Route exact path="/state-city-list/" component={StateCityList} />
          <Route exact path="/city-list/" component={CityList} />
          <Route exact path="/edit-city/:id/" component={UpdateCity} />
          <Route exact path="/edit-package/:id/" component={EditPackage} />
          <Route exact path="/add-logo/" component={AddLogo} />
          <Route exact path="/change-admin-password/" component={Changepassword} />
          <Route exact path="/logout/" component={LogOut} />
          <Route exact path="/add-front-social/" component={AddFrontSocial} />
          <Route exact path="/front-social-list/" component={FrontSocialList} />
          <Route exact path="/edit-front-social/:id/" component={EditFrontSocial} />
          <Route exact path="/add-popular-location/" component={AddPopularLocation} />
          <Route exact path="/popular-location-list/" component={PopularLocationList} />
          <Route exact path="/edit-popular-location/:id/" component={EditPopularLocation} />
          <Route exact path="/add-for-the-injured/" component={AddForTheInjured} />
          <Route exact path="/for-the-injured-list/" component={ForTheInjuredList} />
          <Route exact path="/edit-for-the-injured/:id/" component={EditForTheInjured} />
          <Route exact path="/for-attorney-list/" component={ForAttorneyList} />
          <Route exact path="/add-for-attorney/" component={AddForAttorney} />
          <Route exact path="/edit-for-attorney/:id/" component={EditForAttorney} />
          <Route exact path="/copyright/" component={Copyright} />
          <Route exact path="/add-sidebar-banner/" component={AddSidebarBanner} />
          <Route exact path="/sidebar-banner/" component={SidebarBanner} />
          <Route exact path="/sidebar-banner/:id/" component={UpdateSidebarBanner} />
          <Route exact path="/get-published/" component={GetPublished} />
          <Route exact path="/home-page/" component={HomePage} />
          {/* front routes */}
          <Route exact path="/claim-profile-search/" component={ClaimProfileSearch} />
          <Route exact path="/claim-profile/:name/:lawyerstate/:lawyercity/" component={ClaimProfile} />
          <Route exact path="/single-claim-profile/:id/" component={SingleClaimProfile} />
          <Route exact path="/choose-method/:id/" component={ChooseClaimMethod} />
          <Route exact path="/" component={Home} />
          <Route exact path="/findlawyer/" component={Findlawyer} />
          <Route exact path="/find-a-lawyer/" component={Flawyers} />
          <Route exact path={["/practice-areas/:id/"]} component={CCity} />
          <Route exact path="/practice-area/" component={FrontPracticeAreas} />
          <Route exact path={["/practice-areas/", "/practice-areas/:state/", "/practice-areas/:state/:city/"]} component={PracticeAreas} />
          <Route exact path="/practice-areas-lawyer-list/:stateid/:id/:practice/" component={PracticeAreasLawyerList} />
          <Route exact path="/lawyer/:id/" component={FrontLawyerProfile} />
          <Route exact path="/:injury/" component={LawyerStates} />
          <Route exact path="/:injury/:state/" component={LawyerCities} />
          <Route path="/:practice/:lawyerstate/:lawyercity/" component={LawyerProfileByPractice} />
          <Redirect to="/" />
        </Switch>
        </AdminLayout>
      </Router>
    )
  } else {
    return (
      <Router basename='/'>
        <Header isLogin={false} loginType="" />
        <Switch>
          <Route path="/:url*" exact strict render={({ location }) => {
            if (location.pathname.substr(-1) !== '/') {
              return <Redirect to={`${location.pathname}/`} />;
            }
            return null
          }}/>
          <Route exact path="/" component={Home} />
          <Route exact path="/login/" component={AdminSignIn} />
          <Route exact path="/claim-profile-search/" component={ClaimProfileSearch} />
          <Route exact path="/claim-profile/:name/:lawyerstate/:lawyercity/" component={ClaimProfile} />
          <Route exact path="/single-claim-profile/:id/" component={SingleClaimProfile} />
          <Route exact path="/choose-method/:id/" component={ChooseClaimMethod} />
          <Route exact path="/forgot-password/" component={ForgetPassword} />
          <Route exact path="/reset-password/:resettocken/" component={ResetPassword} />
          <Route exact path="/findlawyer/" component={Findlawyer} />
          <Route exact path="/practice-areas-lawyer-list/:stateid/:id/:practice/" component={PracticeAreasLawyerList} />
          <Route exact path="/sign-in/" component={Signin} />
          <Route exact path="/sign-in/:token/" component={Signin} />
          <Route exact path="/signup/" component={Signup} />
          <Route exact path="/missing-information/" component={MissingInformation} />
          <Route exact path="/get-published/" component={GetPublished} />
          <Route exact path="/lawyer/:id/" component={FrontLawyerProfile} />
          <Route exact path="/:injury/" component={LawyerStates} />
          <Route exact path="/:injury/:state/" component={LawyerCities} />
          <Route exact path="/:practice/:lawyerstate/:lawyercity/" component={LawyerProfileByPractice} />
          <Redirect to="/" />
        </Switch>
        <Footer />
      </Router>
    )
  }
}

export default Routes